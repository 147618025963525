<template>
    <div v-if="order">
      <b-dropdown-item v-if="!excludeDetails" :to="`/orders/${order.order_id}`">
        Details
      </b-dropdown-item>
      <div v-for="(allowed, actionName) in order.actions" :key="actionName">
                  <b-dropdown-item
                      v-if="isAllowed(allowed, actionName)"
                      @click="executeAction(actionName, order)">
                          {{  translateActionName(actionName) }}
                  </b-dropdown-item>
      </div>
      <modalSchedulePickup
        :order="selectedOrder"
        :isVisible="showSchedulePickupModal"
        @close="resetSchedulePickupModal">
      </modalSchedulePickup>
      <modalAddProductionInfo
        :order="selectedOrder"
        :isVisible="showAddProductionInfoModal"
        @close="resetAddProductionInfoModal">
      </modalAddProductionInfo>
      <modalDistributionInfo
        v-if="showDistributionInfoModal"
        :is-visible="showDistributionInfoModal"
        :order="selectedOrder"
        :with-extra-fields="withExtraFields"
        :is-editing="isEditingDistributionInfo"
        @close="onCloseDistributionInfoModal"
        @ok="onSaveDistributionInfoModal">
      </modalDistributionInfo>
      <modalCancelPickup
        :order="selectedOrder"
        :isVisible="showCancelPickupModal"
        @close="resetCancelPickupModal">
      </modalCancelPickup>
    </div>
</template>

<script>

import axios from 'axios';
import translations from '@/translations';
import { ORDER_STATUS } from '@/scripts/constants';
import modalSchedulePickup from './ModalSchedulePickup.vue';
import modalCancelPickup from './ModalCancelPickup.vue';
import modalAddProductionInfo from './ModalAddProductionInfo.vue';
import modalDistributionInfo from './ModalDistributionInfo.vue';

const ACTION = {
  AllowSchedulePickup: 'allow_schedule_pickup',
  AllowCancelPickup: 'allow_cancel_pickup',
  AllowCreateReturnLabel: 'allow_create_return_label',
  AllowArchiveOrder: 'allow_archive_order',
  AllowAddProductionInfo: 'allow_add_production_info',
  AllowAddDistributionInfo: 'allow_add_distribution_info',
  AllowEditDistributionInfo: 'allow_edit_distribution_info',
  AllowAddDistributionInfoWithExtraFields: 'allow_add_distribution_info_with_extra_fields',
  AllowCancelOrder: 'allow_cancel_order',
  AllowChangeStatusToReturned: 'allow_change_status_to_returned',
  AllowChangeStatusToOnHold: 'allow_change_status_to_on_hold',
  AllowChangeStatusToRequestSent: 'allow_change_status_to_request_sent',
  AllowReplaceOrder: 'allow_replace_order',
};

export default {
  name: 'order-allowed-actions',
  components: {
    modalSchedulePickup,
    modalCancelPickup,
    modalAddProductionInfo,
    modalDistributionInfo,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    excludeDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedOrder: null,
      showCancelPickupModal: false,
      showSchedulePickupModal: false,
      showAddProductionInfoModal: false,
      showDistributionInfoModal: false,
      isEditingDistributionInfo: false,
      translations: translations.orders,
      pickupConfig: {
        intervals: [],
        maxDate: '',
        minDate: '',
      },
    };
  },
  methods: {
    translateActionName(actionName) {
      return this.translations.actions[actionName] || actionName;
    },
    translateStatus(status) {
      return this.translations.status[status] || status;
    },
    isAllowed(allowed, actionName) {
      // Check role permissions to archive order
      if (actionName === ACTION.AllowArchiveOrder && !this.$acl.check('orderManagement.orders.archive')) {
        return false;
      }

      if (actionName === ACTION.AllowAddProductionInfo && !this.$acl.check('orderManagement.orders.productionInfo.create')) {
        return false;
      }

      return allowed;
    },
    executeAction(actionName, order) {
      switch (actionName) {
        case ACTION.AllowSchedulePickup:
          this.toggleSchedulePickupModal(order);
          break;

        case ACTION.AllowCancelPickup:
          this.toggleCancelPickupModal(order);
          break;

        case ACTION.AllowCreateReturnLabel:
          this.createReturnLabel(order.order_id);
          break;

        case ACTION.AllowArchiveOrder:
          this.archiveOrder(order.order_id);
          break;

        case ACTION.AllowAddProductionInfo:
          this.toggleAddProductionModal(order);
          break;

        case ACTION.AllowAddDistributionInfo:
          this.toggleDistributionInfoModal(order, false);
          break;

        case ACTION.AllowEditDistributionInfo:
          this.toggleDistributionInfoModal(order, true);
          break;

        case ACTION.AllowAddDistributionInfoWithExtraFields:
          this.toggleDistributionInfoWithExtraFieldsModal(order);
          break;

        case ACTION.AllowCancelOrder:
          this.cancelOrder(order.order_id);
          break;

        case ACTION.AllowChangeStatusToReturned:
          this.updateStatus(order.order_id, ORDER_STATUS.Returned);
          break;

        case ACTION.AllowChangeStatusToOnHold:
          this.updateStatus(order.order_id, ORDER_STATUS.OnHold);
          break;

        case ACTION.AllowChangeStatusToRequestSent:
          this.updateStatus(order.order_id, ORDER_STATUS.RequestSent);
          break;

        case ACTION.AllowReplaceOrder:
          this.replaceOrder(order);
          break;

        default:
          console.error(`Action ${actionName} not implemented`);
          break;
      }
    },
    toggleSchedulePickupModal(order) {
      this.selectedOrder = order;
      this.showSchedulePickupModal = true;
    },
    toggleCancelPickupModal(order) {
      this.selectedOrder = order;
      this.showCancelPickupModal = true;
    },
    toggleAddProductionModal(order) {
      this.selectedOrder = order;
      this.showAddProductionInfoModal = true;
    },
    async createReturnLabel(orderID) {
      const shouldCreate = await this.$bvModal.msgBoxConfirm('Are you sure you want to create a new return label?', {
        title: 'Confirm return label creation',
        okTitle: 'Yes',
      });
      if (!shouldCreate) {
        return;
      }
      this.loadingPage = true;
      const created = await this.$store.dispatch('OrderManagement/createReturnLabel', orderID);
      if (created) {
        this.loadingPage = false;
        this.$router.go();
      }
    },
    resetSchedulePickupModal() {
      this.selectedOrder = null;
      this.showSchedulePickupModal = false;
    },
    resetCancelPickupModal() {
      this.selectedOrder = null;
      this.showCancelPickupModal = false;
    },
    async archiveOrder(orderID) {
      const shouldArchive = await this.$bvModal.msgBoxConfirm('Order will be flagged as Archived. This action cannot be reversed.', {
        title: 'Are you sure you want to archive this order?',
        okTitle: 'Archive',
      });
      if (!shouldArchive) {
        return;
      }
      this.loading = true;
      axios
        .put(`v1/order/${orderID}/archive`)
        .then(() => {
          this.$noty.success('Order archived with success!');
          this.$router.go();
        })
        .catch(err => {
          this.$noty.error('Unable to archive order: ', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async cancelOrder(orderID) {
      const shouldCancel = await this.$bvModal.msgBoxConfirm('Order will be canceled. This action cannot be reversed.', {
        title: 'Are you sure you want to cancel this order?',
        okTitle: 'Cancel Order',
        cancelTitle: 'Back',
      });
      if (!shouldCancel) {
        return;
      }
      this.loading = true;

      const payload = {
        orderID,
        status: ORDER_STATUS.Canceled,
      };

      this.loadingPage = true;
      this.$store.dispatch('OrderManagement/updateOrderStatus', payload)
        .then(() => {
          this.loadingPage = false;
          this.$router.go();
        })
        .catch(error => {
          console.error('Unable to update order status:', error);
        });
    },
    async updateStatus(orderID, newStatus) {
      const confirmUpdate = await this.$bvModal.msgBoxConfirm(`This order status will be updated to ${this.translateStatus(newStatus)}`, {
        title: 'Are you sure you want to continue?',
        okTitle: 'Update Order',
        cancelTitle: 'Back',
      });
      if (!confirmUpdate) {
        return;
      }
      this.loading = true;

      const payload = {
        orderID,
        status: newStatus,
      };

      this.loadingPage = true;
      this.$store.dispatch('OrderManagement/updateOrderStatus', payload)
        .then(() => {
          this.$router.go();
        })
        .catch(error => {
          console.error('Unable to update order status:', error);
        }).finally(() => {
          this.loadingPage = false;
        });
    },
    replaceOrder(order) {
      this.$router.push({
        name: 'NewManualOrder',
        query: {
          op: 'replacement',
        },
        params: {
          patientId: order.patient_id,
          clientID: order.client_id,
          orderID: order.order_id,
          productType: order.product_type,
          sku: order.sku,
          shipVia: order.ship_via,
          countryStateCode: order.patient_state,
          componentType: order.component_type,
        },
      });
    },
    resetAddProductionInfoModal() {
      this.selectedOrder = null;
      this.showAddProductionInfoModal = false;
    },
    toggleDistributionInfoModal(order, isEditing = false) {
      this.selectedOrder = order;
      this.showDistributionInfoModal = true;
      this.withExtraFields = false;
      this.isEditingDistributionInfo = false;
      if (isEditing) {
        this.isEditingDistributionInfo = true;
        this.withExtraFields = order.distribution_info?.return_label_file !== null;
      }
    },
    toggleDistributionInfoWithExtraFieldsModal(order) {
      this.selectedOrder = order;
      this.showDistributionInfoModal = true;
      this.withExtraFields = true;
    },
    onCloseDistributionInfoModal() {
      this.selectedOrder = null;
      this.showDistributionInfoModal = false;
    },
    onSaveDistributionInfoModal() {
      this.onCloseDistributionInfoModal();
      this.$router.go();
    },
  },
};
</script>
