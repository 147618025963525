<template>
    <b-modal
      centered
      size="xl"
      button-size="sm"
      title="Schedule pickup"
      :ok-title="'Save'"
      @ok="handleOk"
      @show="resetModal"
      @hidden="close"
      @cancel="close"
      :visible="isVisible">
       <b-form ref="form" @submit.stop.prevent="handleSubmit">
        <input v-if="order" type="hidden" name="orderID" :value="order.order_id">
        <b-form-group
          label="Availability"
          label-size="lg">
          <b-row>
           <b-col cols="6">
            <b-form-group
              id="select-date"
              label="Date *"
              label-for="availability-input"
              description="Select a day in which the pickup should be performed">
              <vue-ctk-date-time-picker
                format="YYYY-MM-DD"
                id="pickup-date"
                :min-date="this.pickupConfig.minDate"
                :max-date="this.pickupConfig.maxDate"
                :no-weekends-days=true
                :disabled-dates="disabledDates"
                v-model="form.pickupDate"
                formatted="ll"
                :error="dateRangeValidator(form.pickupDate)"
                only-date/>
            </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                id="select-time"
                label="Interval *"
                label-for="availability-input"
                description="Select time window in which the pickup should be performed"
                invalid-feedback="Pickup interval is required"
                required
                :state="isValid('form.pickupInterval')">
                <b-form-select
                  v-model="form.pickupInterval"
                  required
                  :state="isValid('form.pickupInterval')">
                    <b-form-select-option v-for="(interval, index) in this.pickupConfig.intervals" :key="index" :value="interval.name">
                      {{ interval.description }}
                    </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
        </b-row>
        </b-form-group>
        <b-form-group
        label="Address"
        label-size="lg">
          <b-row>
          <b-col cols="6">
            <b-form-group
              id="confirm-address"
              label="Address line 1 *"
              label-for="address-line-1-input"
              description="Check if the members address is OK">
              <b-form-input
              id="address-line-1-input"
              v-model="form.address.addressLine1"
              required
              :state="isValid('form.address.addressLine1')">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
              <b-form-group
              id="confirm-address-line-2"
              label="Address line 2"
              label-for="address-line-2-input"
              description="Complement the addres line">
              <b-form-input
              id="address-line-2-input"
              v-model="form.address.addressLine2"
              required
              :state="isValid('form.address.addressLine2')">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              id="zip-code"
              label="Zip code *"
              label-for="zip-code-input"
              description="Add a zip code">
              <b-form-input
              id="zip-code-input"
              v-model="form.address.zipCode"
              required
              :state="isValid('form.address.zipCode')">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3">
            <b-form-group
              id="city"
              label="City *"
              label-for="city-input"
              description="Add a City">
              <b-form-input
              id="city-input"
              v-model="form.address.city"
              required
              :state="isValid('form.address.city')">
              </b-form-input>
            </b-form-group>
          </b-col>
           <b-col cols="3">
            <b-form-group
              id="state"
              label="State *"
              label-for="state-input"
              description="Add a State">
              <b-form-input
              id="state-input"
              v-model="form.address.state"
              required
              :state="isValid('form.address.state')">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              id="country"
              label="Country *"
              label-for="country-input"
              description="Add a Country">
              <b-form-input
              id="country-input"
              v-model="form.address.country"
              required
              :state="isValid('form.address.country')">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        </b-form-group>
       </b-form>
    </b-modal>
</template>

<script>
import { minLength, required } from 'vuelidate/lib/validators';
import errorHandler from '@/mixins/errorHandler';
import moment from 'moment';

export default {
  name: 'modal-schedule-pickup',
  mixins: [ errorHandler ],
  data() {
    return {
      form: {
        pickupDate: null,
        pickupInterval: null,
        address: {
          addressLine1: null,
          addressLine2: null,
          zipCode: null,
          city: null,
          state: null,
          country: null,
        },
      },
      pickupConfig: {
        intervals: [],
        pickupDays: [],
        maxDate: '',
        minDate: '',
      },
    };
  },
  props: {
    order: {
      type: Object,
      default: () => ({
        order_id: null,
      }),
    },
    isVisible: Boolean,
  },
  methods: {
    dateRangeValidator(field) {
      if (!field) {
        return true;
      }
      return false;
    },
    ok() {
      this.$emit('ok');
      this.isVisible = false;
    },
    close() {
      this.$emit('close');
    },
    checkFormValidity() {
      const fields = [
        'order.order_id',
        'form.pickupInterval',
        'form.pickupDate',
        'form.address.addressLine1',
        'form.address.zipCode',
        'form.address.city',
        'form.address.state',
        'form.address.country',
      ];

      return fields.every(field => this.isValid(field));
    },
    async handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }

      await this.schedulePickup();
      this.ok();
    },
    async schedulePickup() {
      const payload = {
        orderID: this.order.order_id,
        pickupDate: this.form.pickupDate,
        timeWindow: this.form.pickupInterval,
        addressLine1: this.form.address.addressLine1,
        addressLine2: this.form.address.addressLine2,
        zipCode: this.form.address.zipCode,
        city: this.form.address.city,
        state: this.form.address.state,
        country: this.form.address.country,
      };

      await this.$store.dispatch('OrderManagement/schedulePickup', payload);
    },
    resetModal() {
      this.form.pickupDate = '';
      this.form.pickupInterval = '';
      this.form.address.addressLine1 = '';
      this.form.address.addressLine2 = '';
      this.form.address.zipCode = '';
      this.form.address.city = '';
      this.form.address.state = '';
      this.form.address.country = '';
      this.getOrderDetails(this.order.order_id);
      this.getPickupConfigs();
    },
    async getOrderDetails() {
      const result = await this.$store.dispatch('OrderManagement/getOrderDetails', this.order.order_return.order_forward_id);
      if (result) {
        this.form.address.addressLine1 = result.data.order.patient_address_1;
        this.form.address.addressLine2 = result.data.order.patient_address_2;
        this.form.address.zipCode = result.data.order.patient_zip_code;
        this.form.address.city = result.data.order.patient_city;
        this.form.address.state = result.data.order.patient_state;
        this.form.address.country = result.data.order.patient_country;
      }
    },
    async getPickupConfigs() {
      const pickupConfigs = await this.$store.dispatch('OrderManagement/getPickupIntervals', this.order.order_id);

      this.pickupConfig.intervals = pickupConfigs.pickupIntervals;
      this.pickupConfig.pickupDays = pickupConfigs.pickupDays;
      this.pickupConfig.maxDate = pickupConfigs.maxPickupDate;
      this.pickupConfig.minDate = pickupConfigs.minPickupDate;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
  },
  computed: {
    rules() {
      return {
        order: {
          orderID: { required },
        },
        form: {
          address: {
            addressLine1: { required, minLength: minLength(1) },
            zipCode: { required },
            city: { required },
            state: { required },
            country: { required },
          },
          pickupInterval: { required },
          pickupDate: { required },
        },
      };
    },
    disabledDates() {
      if (this.pickupConfig.minDate === '' || this.pickupConfig.maxDate === '') {
        return [];
      }

      const minDate = moment.min(this.pickupConfig.minDate);
      const maxDate = moment.max(this.pickupConfig.maxDate);

      const allDatesInRange = [];
      const currentDate = moment(minDate);
      while (currentDate.isSameOrBefore(maxDate)) {
        allDatesInRange.push(currentDate.format('YYYY-MM-DD'));
        currentDate.add(1, 'days');
      }

      return allDatesInRange.filter(date => !this.pickupConfig.pickupDays.includes(date));
    },
  },
  validations() {
    return this.rules;
  },
};
</script>
