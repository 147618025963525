<template>
  <b-modal
    centered
    size="lg"
    button-size="sm"
    :title="isEditing ? 'Edit distribution information': 'Add distribution information'"
    :visible="isVisible"
    :ok-title="'Save'"
    @show="resetModal"
    @ok="handleOk"
    @hidden="close"
    @cancel="close">
    <b-card>
      <b-card class="mb-4">
        <p class="h5">
          <span>Order ID:<small class="text-muted ml-2">{{ order.order_id }}</small></span>
          <span class="ml-4">Program ID:<small class="text-muted ml-2">{{ order.patient_id }}</small></span>
        </p>
      </b-card>
      <b-form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group class="mt-2"
                      label="Tracking Number *"
                      label-for="name-input"
                      invalid-feedback="Tracking number is required"
                      :state="isTrackingNumberValid">
          <b-form-input
            id="name-input"
            v-model="form.trackingNumber"
            :state="isTrackingNumberValid"
            required></b-form-input>
        </b-form-group>
        <b-form-group
          label="Tracking Link"
          label-for="ip-tracking-link"
          invalid-feedback="Tracking link is invalid"
          :state="isTrackingLinkValid">
          <b-form-input
            id="ip-tracking-link"
            v-model="form.trackingLink"
            :state="isTrackingLinkValid">
            </b-form-input>
        </b-form-group>
        <b-form-group
          id="pro-forma-holder"
          :label="proFormaLabel"
          label-for="pro-forma-pdf-input"
          invalid-feedback="Pro Forma file is required">
          <b-form-file
            v-model="form.proFormaFile"
            :state="isProFormaFileValid"
            placeholder="Upload pro forma in format .pdf"
            accept="application/pdf"
            required>
          </b-form-file>
        </b-form-group>
        <b-form-group
          id="shipping-label-holder"
          label="Shipping Label File *"
          label-for="shipping-label-file-input"
          invalid-feedback="Shipping Label file is required">
          <b-form-file
            v-model="form.shippingLabelFile"
            :state="isShippingLabelFileValid"
            placeholder="Upload shipping label in format .pdf"
            accept="application/pdf"
            required>
          </b-form-file>
        </b-form-group>
        <b-form-group
          v-if="withExtraFields"
          id="return-label-holder"
          label="Return Label File *"
          label-for="return-label-file-input"
          invalid-feedback="Return Label file is required">
          <b-form-file
            v-model="form.returnLabelFile"
            :state="isReturnLabelFileValid"
            placeholder="Upload return label in format .pdf"
            accept="application/pdf"
            required>
          </b-form-file>
        </b-form-group>
      </b-form>
    </b-card>
  </b-modal>
</template>

<script>
import {
  required, requiredIf, requiredUnless, url,
} from 'vuelidate/lib/validators';
import errorHandler from '@/mixins/errorHandler';

export default {
  name: 'modal-add-distribution-info',
  mixins: [ errorHandler ],
  data() {
    return {
      form: {
        trackingNumber: null,
        trackingLink: '',
        proFormaFile: null,
        shippingLabelFile: null,
        returnLabelFile: null,
      },
      isTrackingNumberValid: null,
      isTrackingLinkValid: null,
      isProFormaFileValid: null,
      isShippingLabelFileValid: null,
      isReturnLabelFileValid: null,
    };
  },
  props: {
    order: Object,
    isVisible: Boolean,
    withExtraFields: Boolean,
    isEditing: Boolean,
  },
  methods: {
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    async handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }

      await this.handleDistributionInfo();
      this.ok();
    },
    async handleDistributionInfo() {
      const payload = {
        orderID: this.order.order_id,
        trackingNumber: this.form.trackingNumber,
        trackingLink: this.form.trackingLink,
        shippingLabelFile: this.form.shippingLabelFile,
        proFormaFile: this.form.proFormaFile,
      };

      if (this.withExtraFields) {
        payload.returnLabelFile = this.form.returnLabelFile;
      }

      if (this.isEditing) {
        payload.distributionID = this.order.distribution_info.id;
        await this.$store.dispatch('OrderManagement/updateDistributionInfo', payload);
        return;
      }

      await this.$store.dispatch('OrderManagement/addDistributionInfo', payload);
    },
    isURLValid(inputUrl) {
      try {
        return Boolean(new URL(inputUrl));
      } catch (e) {
        return false;
      }
    },
    checkFormValidity() {
      this.isTrackingNumberValid = this.isValid('form.trackingNumber');
      this.isTrackingLinkValid = this.isValid('form.trackingLink');
      this.isProFormaFileValid = this.isValid('form.proFormaFile');
      this.isShippingLabelFileValid = this.isValid('form.shippingLabelFile');
      this.isReturnLabelFileValid = this.isValid('form.returnLabelFile');

      return this.isTrackingNumberValid
      && this.isTrackingLinkValid
      && this.isProFormaFileValid
      && this.isShippingLabelFileValid
      && this.isReturnLabelFileValid;
    },
    resetModal() {
      this.trackingNumber = null;
      this.trackingLink = '';
      this.isTrackingNumberValid = null;
      this.isTrackingLinkValid = null;
      this.proFormaFile = null;
      this.shippingLabelFile = null;
      this.returnLabelFile = null;
    },
    close() {
      this.$emit('close');
    },
    ok() {
      this.$emit('ok');
    },
  },
  computed: {
    rules() {
      return {
        form: {
          trackingNumber: { required },
          trackingLink: { url },
          shippingLabelFile: { required },
          proFormaFile: {
            requiredUnless: requiredUnless(() => this.withExtraFields),
          },
          returnLabelFile: {
            requiredIf: requiredIf(() => this.withExtraFields),
          },
        },
      };
    },
    proFormaLabel() {
      return this.withExtraFields ? 'Pro Forma File' : 'Pro Forma File *';
    },
  },
  validations() {
    return this.rules;
  },
};
</script>
