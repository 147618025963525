<template>
    <b-modal
      centered
      size="lg"
      button-size="sm"
      title="Add Production Info"
      v-if="order"
      :ok-title="'Save'"
      @ok="handleOk"
      @show="resetModal"
      @hidden="close"
      @cancel="close"
      :visible="isVisible">
       <b-card class="mb-4">
        <p class="h5">
          <span>Order ID:<small class="text-muted ml-2">{{ order.order_id }}</small></span>
          <span class="ml-4">Program ID:<small class="text-muted ml-2">{{ order.patient_id }}</small></span>
        </p>
      </b-card>
       <b-form ref="form" @submit.stop.prevent="handleSubmit">
        <input v-if="order" type="hidden" name="orderID" :value="order.order_id">
        <b-form-group
        label-size="lg">
          <b-row>
          <b-col cols="12">
            <b-form-group
              id="kit-serial-number-holder"
              label="Kit Serial Number *"
              label-for="kit-serial-number-input"
              description="Add a kit serial number">
              <b-form-input
              id="kit-serial-number-input"
              v-model="form.kitSerialNumber"
              required
              :state="isValid('form.kitSerialNumber')">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        </b-form-group>
       </b-form>
    </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import errorHandler from '@/mixins/errorHandler';
import Vue from 'vue';

export default {
  name: 'modal-add-production-info',
  mixins: [ errorHandler ],
  data() {
    return {
      form: {
        kitSerialNumber: null,
      },
    };
  },
  props: {
    order: {
      type: Object,
      default: () => ({
        order_id: null,
      }),
    },
    isVisible: Boolean,
  },
  methods: {
    ok() {
      this.$emit('ok');
      this.isVisible = false;
    },
    close() {
      this.$emit('close');
    },
    checkFormValidity() {
      const fields = [
        'form.kitSerialNumber',
      ];

      return fields.every(field => this.isValid(field));
    },
    async handleSubmit() {
      if (!this.checkFormValidity()) {
        const errMsg = 'The form is not valid. Please check the fields.';
        Vue.prototype.$noty.error(errMsg);
        return;
      }

      await this.addProductionInfo();
    },
    async addProductionInfo() {
      const payload = {
        orderID: this.order.order_id,
        kitSerialNumber: this.form.kitSerialNumber,
      };

      this.$store.dispatch('OrderManagement/addProductionInfo', payload)
        .then(() => {
          this.ok();
          this.$router.go();
        })
        .catch(error => {
          console.error(error);
        });
    },
    resetModal() {
      this.form.kitSerialNumber = null;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
  },
  computed: {
    rules() {
      return {
        order: {
          orderID: { required },
        },
        form: {
          kitSerialNumber: { required },
        },
      };
    },
  },
  validations() {
    return this.rules;
  },
};
</script>
