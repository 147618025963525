<template>
  <b-modal
    centered
    size="md"
    button-size="sm"
    title="Cancel Pickup"
    :ok-title="'Confirm'"
    @ok="handleOk"
    @show="resetModal"
    @hidden="close"
    @cancel="close"
    :visible="isVisible">
     <b-form ref="form" @submit.stop.prevent="handleSubmit">
      <p>Are you sure you want to cancel this pickup? This action cannot be reversed.</p>
     </b-form>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import errorHandler from '@/mixins/errorHandler';

export default {
  name: 'modal-cancel-schedule-pickup',
  mixins: [ errorHandler ],
  data() {
    return {
      pickupRequestNumber: null,
    };
  },
  props: {
    order: {
      type: Object,
      default: () => ({
        order_id: null,
        pickup_schedules: [],
      }),
    },
    isVisible: Boolean,
  },
  methods: {
    ok() {
      this.$emit('ok');
      this.isVisible = false;
    },
    close() {
      this.$emit('close');
    },
    async handleSubmit() {
      if (!this.order.pickup_schedules.length) {
        this.$noty.error('Order without pickup schedules');
        return;
      }
      this.pickupRequestNumber = this.order.pickup_schedules[0].pickup_request_number;
      await this.cancelPickup(this.pickupRequestNumber, this.order.order_id);
      this.$router.go();
      this.ok();
    },
    async cancelPickup(pickupRequestNumber, orderID) {
      const payload = {
        pickupRequestNumber,
        orderID,
      };

      await this.$store.dispatch('OrderManagement/cancelPickup', payload);
    },
    resetModal() {
      this.pickupRequestNumber = null;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
  },
  computed: {
    rules() {
      return {
        order: {
          orderID: { required },
          pickup_schedules: { required },
        },
      };
    },
  },
  validations() {
    return this.rules;
  },
};
</script>
