import axios from 'axios';
import { ORDER_STATUS, ORDER_TYPE } from '@/scripts/constants';

export default {
  methods: {
    async updateOrderStatus(orderID, newStatus, orderType) {
      if (newStatus === ORDER_STATUS.Returned && orderType === ORDER_TYPE.RETURN) {
        const shouldUpdate = await this.$bvModal.msgBoxConfirm(
          'Update to returned is not reversible. Are you sure?', {
            title: 'Warning!',
            okTitle: 'Confirm',
          },
        );

        if (!shouldUpdate) {
          return;
        }
      }

      this.loading = true;
      axios
        .put(`v1/order/${orderID}/status/${newStatus}`)
        .then(() => {
          this.$noty.success('Order status updated with success!');
          this.$router.go();
        })
        .catch(err => {
          this.$noty.error('Unable to update order status: ', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async isOrderManagementDisable(clientID) {
      this.isLoading = true;
      try {
        const client = await this.$store.dispatch('Core/getClient', clientID);

        if (!client) {
          this.$noty.error('Failed to get client configuration');
          return null;
        }

        return client.configuration?.toggles?.order_management_disabled || false;
      } catch (err) {
        this.$noty.error('Error loading client');
        return null;
      } finally {
        this.isLoading = false;
      }
    },
    isUpdatable(status) {
      return status !== ORDER_STATUS.Returned;
    },
    allowUpdateToReturned(actions) {
      return actions && actions.allow_change_status_to_returned;
    },
    searchCanBeExported(count) {
      this.countReturns = count;

      this.exportDisabled = this.countReturns > this.limitExportableOrders;
    },
    canExport() {
      const user = this.$store.getters['User/profile'];

      return this.isAdmin(user) || this.isProduction(user);
    },
    hasActions(actions) {
      return Object.values(actions).some(value => value === true);
    },
    async createReturnLabel(orderID) {
      const shouldCreate = await this.$bvModal.msgBoxConfirm('Are you sure you want to create a new return label?', {
        title: 'Confirm return label creation',
        okTitle: 'Yes',
      });
      if (!shouldCreate) {
        return;
      }
      this.loadingPage = true;
      const created = await this.$store.dispatch('OrderManagement/createReturnLabel', orderID);
      if (created) {
        this.loadingPage = false;
        this.$router.go();
      }
    },
  },
};
